<ion-menu side="end" contentId="main-content">
  <ion-content>
    <ion-list>
      <ion-item-divider>
        <ion-label> Controls </ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-label position="fixed">Bars</ion-label>
        <ion-input
          min="0"
          type="number"
          aria-label="Start Bar"
          [(ngModel)]="settings.startMeasure"
          (change)="settings.startMeasure = $event.target.value"
        ></ion-input>
        &nbsp;-&nbsp;
        <ion-input
          min="0"
          type="number"
          aria-label="End Bar"
          [(ngModel)]="settings.endMeasure"
          (change)="settings.endMeasure = $event.target.value"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          min="0"
          max="10"
          type="number"
          label="Repeat"
          labelPlacement="fixed"
          [(ngModel)]="settings.repeat"
          (ionChange)="settings.repeat = $event.target.value"
        >
        </ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          min="10"
          max="200"
          type="number"
          label="Zoom (%)"
          labelPlacement="fixed"
          [(ngModel)]="settings.zoom"
          (change)="settings.updateZoom($event.target.value, osmd)"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          min="10"
          max="200"
          type="number"
          label="Tempo (%)"
          labelPlacement="fixed"
          [(ngModel)]="settings.speed"
          (change)="settings.speed = $event.target.value"
        ></ion-input>
      </ion-item>

      <ion-item-divider>
        <ion-label> Settings </ion-label>
      </ion-item-divider>

      <ion-item>
        <ion-checkbox disabled="{{ cursor.running }}" [(ngModel)]="settings.checkboxStaveUp">Upper Staff</ion-checkbox>
      </ion-item>
      <ion-item>
        <ion-checkbox disabled="{{ cursor.running }}" [(ngModel)]="settings.checkboxStaveDown"
          >Lower Staff</ion-checkbox
        >
      </ion-item>

      <ion-item>
        <ion-toggle [(ngModel)]="settings.checkboxKeyboard"> {{ 'keyboard' | translate }}</ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label position="fill">Background</ion-label>
        <ion-item
          ><input type="color" [(ngModel)]="settings.backgroundColor" disabled="{{ cursor.running }}"
        /></ion-item>
      </ion-item>

      <ion-item>
        <ion-select
          label="Language"
          interface="popover"
          [(ngModel)]="settings.language"
          (ionChange)="settings.useLanguage($event)"
        >
          <ion-select-option value="gb">🇬🇧 gb</ion-select-option>
          <ion-select-option value="fr">🇫🇷 fr</ion-select-option>
          <ion-select-option value="de">🇩🇪 de</ion-select-option>
          <ion-select-option value="it">🇮🇹 it</ion-select-option>
          <ion-select-option value="es">🇪🇸 es</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item-divider>
        <ion-label>Info</ion-label>
      </ion-item-divider>

      <ion-item>
        <ion-label>{{ midi.device }}</ion-label>
        <ion-note>{{ 'midi' | translate }}</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>{{ version }}</ion-label>
        <ion-note>Version</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-button (click)="notes.connect()">Connect</ion-button>
        </ion-label>
        <ion-note>Serial</ion-note>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<input class="ion-hide" #f type="file" accept=".mxl" (change)="loadFiles($event.target.files)" />

<div class="ion-page" id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button disabled="{{ cursor.running }}" (click)="f.click()">
          <ion-icon size="large" name="folder-open-outline"></ion-icon>
        </ion-button>
        <ion-button disabled="{{ !fileLoaded || cursor.running }}" (click)="play(false)">
          <ion-icon size="large" name="play-outline"></ion-icon>
        </ion-button>
        <ion-button disabled="{{ !fileLoaded || cursor.running }}" (click)="play()">
          <ion-icon size="large" name="volume-medium-outline"></ion-icon>
        </ion-button>
        <ion-button disabled="{{ !cursor.running }}" (click)="cursor.stop(osmd.cursor)">
          <ion-icon size="large" name="stop-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="false">
    <div [style.backgroundColor]="settings.backgroundColor" id="osmdContainer"></div>

    <div *ngIf="!fileLoaded" style="margin: 3em">
      <span style="position: fixed; margin-top: -2em">
        <ion-label *ngIf="fileLoadError" color="danger">
          {{ 'errorFile' | translate }}
        </ion-label>

        <ion-label *ngIf="!midi.available" color="danger">
          {{ 'errorMidi' | translate }}
        </ion-label>
      </span>

      <ion-card>
        <ion-card-header>
          <ion-card-title>Open a Score</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-list>
            <ion-item>1. {{ 'instructions1' | translate }}</ion-item>

            <ion-item>
              2. {{ 'instructions2a' | translate }} &nbsp;<a
                href="https://musicxml.com/music-in-musicxml/"
                target="_blank"
                >MusicXML</a
              >&nbsp; {{ 'instructions2b' | translate }} &nbsp;
            </ion-item>

            <ion-item> 3. {{ 'instructions3' | translate }}</ion-item>
          </ion-list>
        </ion-card-content>

        <ion-button fill="clear" (click)="loadURL('/assets/scores/October.mxl')">October</ion-button>
        <ion-button fill="clear" (click)="loadURL('/assets/scores/Moonlight-Sonata-1st-movement.mxl')">
          Moonlight Sonata
        </ion-button>
        <ion-button fill="clear" (click)="loadURL('/assets/scores/Passacaglia.mxl')">Passacaglia</ion-button>
        <ion-button fill="clear" (click)="loadURL('/assets/scores/Prelude-in-E-Minor.mxl')">
          Prelude in E Minor
        </ion-button>
      </ion-card>
    </div>
  </ion-content>

  <ion-footer *ngIf="settings.checkboxKeyboard">
    <ion-toolbar>
      <app-keyboard></app-keyboard>
    </ion-toolbar>
  </ion-footer>
</div>
